import { ReactElement } from "react";
import { isEqual } from "lodash";
import {
  IconForced,
  IconDrop,
  IconUnforced,
  IconComplete,
} from "../components/icons";
import { Point } from "../app/hooks/usePoints";
import { Event } from "../app/hooks/useEvents";
import {
  colorRedDark,
  colorRedLight,
  colorGreenLight,
  colorGreenDark,
  colorLight,
  colorDrop,
  colorLikeGreen,
} from "./colors";
import { playerIdsInPoint } from "../lib/eventsHelpers";
import { timestampBetween } from "./timeHelpers";
import { formatNumberToString } from "./formatHelpers";

type StatTextOutputs = {
  ratio: string;
  percent: string | null;
  ratioPercent: string;
};

export type analytic = {
  count: number | null;
  chances: number | null;
} | null;

export function scoreSummaryText({
  point,
  nameUs,
  nameThem,
}: {
  point: Point;
  nameUs?: string;
  nameThem?: string;
}): string {
  const outcome = pointResult(point);
  return `${outcome.text} by ${
    outcome.is_won ? nameUs ?? "Us" : nameThem ?? "Them"
  }`;
}

export function colorForPointOutcome(point: Point): string {
  if (pointIsBreak(point)) {
    return colorGreenDark;
  } else if (pointIsHold(point)) {
    return colorGreenLight;
  } else if (pointIsOpponentHold(point)) {
    return colorRedLight;
  } else if (pointIsOpponentBreak(point)) {
    return colorRedDark;
  }
  return colorLight;
}

// index of point in either (optional) pointsArray or not-archived `points` (returns -1 if not found)
export const findPointIndex = ({
  point,
  points,
}: {
  point: Point;
  points: Point[];
}) => points?.findIndex((p) => p?.id === point?.id) ?? -1;

export const pointsUpToPoint = ({
  point,
  points,
}: {
  point: Point;
  points: Point[];
}): Point[] => {
  const maxIndex = point ? findPointIndex({ point, points }) : -1;
  return maxIndex !== -1 ? points.filter((_, i) => i <= maxIndex) : points;
};

function pointsScored({
  points,
  point = null,
  won,
}: {
  points: Point[];
  point?: Point;
  won: boolean;
}): number {
  return pointsUpToPoint({ point, points })
    .filter((point) => point.is_archived === false)
    .reduce(
      (pointTotal, point, index): number =>
        pointTotal + (point.is_won === won ? 1 : 0),
      0
    );
}

export const pointsFor = (points: Point[], point: Point = null) =>
  pointsScored({ points, point, won: true });

export const pointsAgainst = (points: Point[], point: Point = null) =>
  pointsScored({ points, point, won: false });

export function statThreeUpPercentage(
  successes: number,
  chances: number
): StatTextOutputs {
  const ratio = `${successes} / ${chances}`;
  const percent =
    chances <= 0 ? null : formatNumberToString(successes / chances, "percent");

  return {
    ratio: ratio,
    percent: percent,
    ratioPercent: ratio + (!!chances ? " (" + percent + ")" : ""),
  };
}

export function statEndzoneText(points: Point[]): StatTextOutputs {
  const { converted, not_converted_all } = statEndzone(points);
  return { ...statThreeUpPercentage(converted, converted + not_converted_all) };
}

export function statEndzone(points: Point[]): {
  converted: number;
  not_converted_all: number;
  not_converted_forced: number;
  not_converted_other: number;
  not_converted_drop: number;
  not_converted_unknown: number;
} {
  let totals = {
    endzone_scored: 0,
    endzone_failed_forced: 0,
    endzone_failed_other: 0,
    endzone_failed_drop: 0,
    endzone_failed_unknown: 0,
  };

  Object.keys(totals).forEach((key: keyof typeof totals) => {
    totals[key] = points?.reduce((total, p): number => {
      return total + (p?.is_archived ? 0 : (p?.[key as keyof Point] as number));
    }, totals[key]);
  });

  return {
    converted: totals.endzone_scored,
    not_converted_all:
      totals.endzone_failed_forced +
      totals.endzone_failed_other +
      totals.endzone_failed_drop +
      totals.endzone_failed_unknown,
    not_converted_forced: totals.endzone_failed_forced,
    not_converted_other: totals.endzone_failed_other,
    not_converted_drop: totals.endzone_failed_drop,
    not_converted_unknown: totals.endzone_failed_unknown,
  };
}

export type statPossessionsHistogramElementType = {
  scored: number;
  not_scored: number;
};
type statPossessionsHistogramType = {
  [key: string | number]: statPossessionsHistogramElementType | number;
};

export function statPossessionsHistogram(
  points: Point[]
): statPossessionsHistogramType {
  return points?.reduce(
    (
      output: statPossessionsHistogramType,
      point: Point
    ): statPossessionsHistogramType => {
      if (point?.is_archived || point?.is_won === null) {
        return output;
      }

      const possessions = pointOffensivePossessions(point);
      const existingValue: statPossessionsHistogramElementType = (output?.[
        possessions
      ] as statPossessionsHistogramElementType) ?? {
        scored: 0,
        not_scored: 0,
      };
      const affectedKey = point?.is_won ? "scored" : "not_scored";

      return {
        ...output,
        [possessions]: {
          ...existingValue,
          [affectedKey]: existingValue[affectedKey] + 1,
        },
        max: possessions > (output.max as number) ? possessions : output.max,
      };
    },
    { max: 0 }
  );
}

export function statHuckingText(points: Point[]): StatTextOutputs {
  const { completed, incomplete_all } = statHucking(points);
  return { ...statThreeUpPercentage(completed, completed + incomplete_all) };
}

export function statBreakChancesText(points: Point[]): StatTextOutputs {
  const { breakChances, breaksConverted } = statBreakChances(points);
  return { ...statThreeUpPercentage(breaksConverted, breakChances) };
}

export function statHoldChancesText(points: Point[]): StatTextOutputs {
  const { holdChances, holds } = statHoldChances(points);
  return { ...statThreeUpPercentage(holds, holdChances) };
}

export function statBlocks(points: Point[]) {
  return {
    forced: points?.reduce((total, p): number => {
      return total + (p?.is_archived === false ? p?.d_us_forced : 0);
    }, 0),
    other: points?.reduce((total, p): number => {
      return total + (p?.is_archived === false ? p?.d_us_other : 0);
    }, 0),
  };
}

export function StatBlocksElement({
  points,
  small = false,
  hideIfZero,
  className,
}: {
  points: Point[];
  small: boolean;
  hideIfZero?: boolean;
  className?: string;
}) {
  const { forced, other: unforced } = statBlocks(points);
  return (
    <StatSummaryElement
      forced={forced}
      unforced={unforced}
      small={small}
      forcedColor={colorGreenDark}
      unforcedColor={colorGreenLight}
      hideIfZero={hideIfZero}
      className={className}
    />
  );
}

export const statTurnovers = (points: Point[]) => ({
  forced: points?.reduce((total, p): number => {
    return total + (p?.is_archived === false ? p?.d_them_forced : 0);
  }, 0),
  drops: points?.reduce((total, p): number => {
    return total + (p?.is_archived === false ? p?.d_them_drop : 0);
  }, 0),
  other: points?.reduce((total, p): number => {
    return total + (p?.is_archived === false ? p?.d_them_other : 0);
  }, 0),
});

export function StatHucksElement({
  points,
  small = false,
  hideIfZero,
  className,
}: {
  points: Point[];
  small?: boolean;
  hideIfZero?: boolean;
  className?: string;
}) {
  const {
    completed,
    incomplete_forced,
    incomplete_other,
    incomplete_drop,
    incomplete_unknown,
  } = statHucking(points);

  return (
    <StatSummaryElement
      completed={completed}
      forced={incomplete_forced}
      unforced={incomplete_other + incomplete_unknown}
      drops={incomplete_drop}
      small={small}
      forcedColor={colorRedDark}
      unforcedColor={colorRedLight}
      hideIfZero={hideIfZero}
      className={className}
    />
  );
}

export function StatTurnoversElement({
  points,
  small = false,
  hideIfZero,
  className,
}: {
  points: Point[];
  small?: boolean;
  hideIfZero?: boolean;
  className?: string;
}) {
  const { forced, drops, other: unforced } = statTurnovers(points);
  return (
    <StatSummaryElement
      forced={forced}
      unforced={unforced}
      drops={drops}
      small={small}
      forcedColor={colorRedDark}
      unforcedColor={colorRedLight}
      hideIfZero={hideIfZero}
      className={className}
    />
  );
}

const StatSummaryElement = ({
  completed,
  forced,
  unforced,
  drops,
  small = false,
  forcedColor,
  unforcedColor,
  hideIfZero = false,
  className = "",
}: {
  completed?: number;
  forced?: number;
  unforced?: number;
  drops?: number;
  small?: boolean;
  forcedColor?: string;
  unforcedColor?: string;
  hideIfZero?: boolean;
  className?: string;
}): ReactElement =>
  [completed, forced, unforced, drops].every(
    (value) => value === 0 || value === undefined
  ) ? null : (
    <div
      className={`d-inline-flex align-items-center ${className}`}
      style={{ fontSize: small ? "75%" : undefined }}
    >
      {[completed, forced, unforced, drops].map((value, index, values) => {
        // skip if value is null or zeroes are hidden
        if (value === undefined || (value === 0 && hideIfZero)) return null;
        // identify the stat's icon and color
        const Icon = [IconComplete, IconForced, IconUnforced, IconDrop][index];
        const color = [colorLikeGreen, forcedColor, unforcedColor, colorDrop][
          index
        ];
        // add end-margin unless it is the last element
        const isLast = values.slice(index + 1).every((v) => v === undefined);
        return (
          <span key={index} className="d-flex align-items-center">
            {value}&#8239;
            <Icon style={{ color }} className={isLast ? "" : "me-1"} />
          </span>
        );
      })}
    </div>
  );

export const statCleanHolds = (points: Point[]) =>
  points?.reduce(
    (total, p): number =>
      total + (pointIsCleanHold(p) && p?.is_archived === false ? 1 : 0),
    0
  );

export function statBreakChances(points: Point[]): {
  breakPoints: number;
  breakChances: number;
  breaksConverted: number;
  breaksConvertedClean: number;
} {
  return {
    breakPoints: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointDidPull(p) ? 1 : 0), 0),
    breakChances: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + pointBreakChances(p), 0),
    breaksConverted: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointIsBreak(p) ? 1 : 0), 0),
    breaksConvertedClean: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointIsCleanBreak(p) ? 1 : 0), 0),
  };
}

export const statBreaksAgainst = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce((total, p): number => total + (pointIsOpponentBreak(p) ? 1 : 0), 0);

export const statCleanBreaks = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce((total, p) => total + (pointIsCleanBreak(p) ? 1 : 0), 0);

export function statHucking(points: Point[]): {
  completed: number;
  incomplete_all: number;
  incomplete_forced: number;
  incomplete_other: number;
  incomplete_drop: number;
  incomplete_unknown: number;
} {
  let totals = {
    hucks_completed: 0,
    hucks_incomplete_forced: 0,
    hucks_incomplete_other: 0,
    hucks_incomplete_drop: 0,
    hucks_incomplete_unknown: 0,
  };
  Object.keys(totals).forEach((key: keyof typeof totals) => {
    totals[key] = points
      ?.filter((point) => point?.is_archived === false)
      .reduce(
        (total, p): number =>
          total + (p?.is_archived ? 0 : (p?.[key as keyof Point] as number)),
        totals[key]
      );
  });

  return {
    completed: totals.hucks_completed,
    incomplete_all:
      totals.hucks_incomplete_forced +
      totals.hucks_incomplete_other +
      totals.hucks_incomplete_drop +
      totals.hucks_incomplete_unknown,
    incomplete_forced: totals.hucks_incomplete_forced,
    incomplete_other: totals.hucks_incomplete_other,
    incomplete_drop: totals.hucks_incomplete_drop,
    incomplete_unknown: totals.hucks_incomplete_unknown,
  };
}

export function statHoldChances(points: Point[]): {
  holdPoints: number;
  holdChances: number;
  holds: number;
  clean: number;
} {
  return {
    holdPoints: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointDidNotPull(p) ? 1 : 0), 0),
    holdChances: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + pointHoldChances(p), 0),
    holds: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointIsHold(p) ? 1 : 0), 0),
    clean: points
      ?.filter((point) => point?.is_archived === false)
      .reduce((total, p): number => total + (pointIsCleanHold(p) ? 1 : 0), 0),
  };
}

export function statNetBreaks(points: Point[]): number {
  const { breaksConverted: breaksFor } = statBreakChances(points);
  return breaksFor - statBreaksAgainst(points);
}

// assigns a "half break" to indicate the winner if the remainder of points are holds
// -0.5 - half break for opponent
//  0   - neither team awarded half break
// +0.5 - half break for us
export function statHalfBreak(points: Point[]): -0.5 | 0 | 0.5 {
  const currentPoint = points
    ?.filter((p) => !p?.is_archived)
    .reverse()
    .find((p) => p.is_won === null);

  // if an active point (with `is_won` set to null) was not found, assume the game is over and no one has a half break
  if (!currentPoint) {
    return 0;
  }

  const netPointsUs = pointsFor(points) - pointsAgainst(points);
  const netBreaksUs = statNetBreaks(points);
  let analysis = netPointsUs - netBreaksUs;

  if (netPointsUs == netBreaksUs) {
    analysis += currentPoint.did_pull ? -1 : 1;
  }

  return analysis < 1 ? -0.5 : 0.5;
}

export const didReceiveFirstPull = (points: Point[]) =>
  points?.filter((p) => !p.is_archived)?.[0]?.did_pull === false;

export const pointFinished = (point: Point): boolean =>
  !point.is_archived && point.is_won !== null;

export const pointOffensivePossessions = (point: Point) =>
  (pointDidNotPull(point) ? 1 : 0) + pointTotalDefenses(point);

export const pointDefensivePossessions = (point: Point) =>
  (pointDidPull(point) ? 1 : 0) + pointTotalTurnovers(point);

export const pointDidPull = (point: Point) =>
  point?.did_pull && !point?.is_archived;

export const pointDidNotPull = (point: Point) =>
  !point?.did_pull && !point?.is_archived;

export const pointIsBreak = (point: Point, includePending: boolean = false) =>
  pointDidPull(point) && (includePending || point?.is_won);

export const pointIsHold = (point: Point, includePending: boolean = false) =>
  pointDidNotPull(point) && (includePending || point?.is_won);

export const pointIsOpponentBreak = (point: Point) =>
  pointDidNotPull(point) && point?.is_won == false;

export const pointIsOpponentHold = (point: Point) =>
  pointDidPull(point) && point?.is_won == false;

export const pointIsCleanBreak = (point: Point, includePending?: boolean) =>
  pointIsBreak(point, includePending) && pointTotalTurnovers(point) == 0;

export const pointIsCleanHold = (point: Point, includePending?: boolean) =>
  pointIsHold(point, includePending) && pointTotalTurnovers(point) == 0;

export type PointResult = "break_us" | "hold_us" | "break_them" | "hold_them";
export type PointResultFull = {
  breakhold: "break" | "hold";
  result: PointResult;
  clean?: boolean;
  is_won: boolean;
  text: string;
};
export const pointResult = (point: Point): PointResultFull =>
  point?.is_won
    ? point?.did_pull
      ? {
          breakhold: "break",
          result: "break_us",
          clean: pointIsCleanBreak(point),
          is_won: true,
          text: pointIsCleanBreak(point) ? "Clean Break" : "Break",
        }
      : {
          breakhold: "hold",
          result: "hold_us",
          clean: pointIsCleanHold(point),
          is_won: true,
          text: pointIsCleanHold(point) ? "Clean Hold" : "Hold",
        }
    : {
        breakhold: point?.did_pull ? "hold" : "break",
        result: point?.did_pull ? "hold_them" : "break_them",
        is_won: false,
        text: point?.did_pull ? "Hold" : "Break",
      };

export const pointTotalDefenses = (point: Point) =>
  (point?.d_us_forced ?? 0) + (point?.d_us_other ?? 0);

export const pointTotalTurnovers = (point: Point) =>
  (point?.d_them_forced ?? 0) +
  (point?.d_them_other ?? 0) +
  (point?.d_them_drop ?? 0);

export const pointHucksAttempted = (point: Point) =>
  (point?.hucks_completed ?? 0) +
  (point?.hucks_incomplete_unknown ?? 0) +
  (point?.hucks_incomplete_forced ?? 0) +
  (point?.hucks_incomplete_drop ?? 0) +
  (point?.hucks_incomplete_other ?? 0);

export const pointBreakChances = (
  point: Point,
  includePending: boolean = false
) =>
  (includePending || point?.is_won !== null) && pointDidPull(point)
    ? Math.max(pointIsBreak(point) ? 1 : 0, pointTotalDefenses(point))
    : 0;

export const pointHoldChances = (
  point: Point,
  includePending: boolean = false
) =>
  (includePending || point?.is_won !== null) && pointDidNotPull(point)
    ? pointTotalDefenses(point) + 1
    : 0;

export const pointsMaxHoldChances = (
  points: Point[],
  includePending?: boolean
) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false
            ? pointHoldChances(point, includePending)
            : 0
        ),
      0
    );

export const pointsMaxBreakChances = (
  points: Point[],
  includePending?: boolean
) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false
            ? pointBreakChances(point, includePending)
            : 0
        ),
      0
    );

export const pointsMaxDefenses = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false ? pointTotalDefenses(point) : 0
        ),
      0
    );

export const pointsMaxTurnovers = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false ? pointTotalTurnovers(point) : 0
        ),
      0
    );

export const pointsMaxHucks = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false ? pointHucksAttempted(point) : 0
        ),
      0
    );

export const pointsMaxEndzone = (points: Point[]) =>
  points
    ?.filter((point) => point?.is_archived === false)
    .reduce(
      (max, point): number =>
        Math.max(
          max,
          point.is_archived == false
            ? point.endzone_scored +
                point.endzone_failed_forced +
                point.endzone_failed_other +
                point.endzone_failed_drop +
                point.endzone_failed_unknown
            : 0
        ),
      0
    );

// pointsUsesSubbing() - true if the point array makes use of (some) subbing
export function pointsUsesSubbing({
  points,
  events,
}: {
  points: Point[];
  events: Event[];
}): boolean {
  return points.some(
    (p) =>
      playerIdsInPoint({
        events,
        point: p,
      })?.length > 0
  );
}

export function pointHasStarted(point: Point): boolean {
  return (
    point?.is_won !== null ||
    [
      "hucks_completed",
      "hucks_incomplete_forced",
      "hucks_incomplete_other",
      "hucks_incomplete_drop",
      "hucks_incomplete_unknown",
      "endzone_scored",
      "endzone_failed_forced",
      "endzone_failed_other",
      "endzone_failed_drop",
      "endzone_failed_unknown",
      "d_us_forced",
      "d_us_other",
      "d_them_forced",
      "d_them_drop",
      "d_them_other",
    ].some(
      (k) =>
        point?.[k as keyof Point] !== null && point?.[k as keyof Point] !== 0
    )
  );
}

// pointIsValidBias: computes turnovers vs blocks bias in invalid points
// zero     = valid point
// positive = missing turnover(s)
// negative = missing block(s)
export const pointIsValidBias = (point: Point): number =>
  point.is_won === null
    ? 0
    : (point.did_pull ? 0 : 1) -
      (point.is_won ? 1 : 0) +
      pointTotalDefenses(point) -
      pointTotalTurnovers(point);

// pointIsValid: checks that possessions by each team sum to valid point
export const pointIsValid = (point: Point): boolean =>
  pointIsValidBias(point) === 0;

export const possessionIndex = (point: Point): number =>
  pointTotalDefenses(point) + pointTotalTurnovers(point);

export const possessionIndexIsOffense = (
  point: Point,
  index: number
): boolean => index % 2 === (point.did_pull ? 1 : 0);

export const possessionIsOffense = (point: Point): boolean =>
  possessionIndex(point) % 2 === (point.did_pull ? 1 : 0);

// stat functions for aggregation
export function analyticsBreakPoints(point: Point): analytic {
  return !point ||
    pointDidNotPull(point) ||
    point?.is_archived ||
    point?.is_won === null
    ? null
    : {
        count: point?.is_won ? 1 : 0,
        chances: 1,
      };
}
export function analyticsHoldPoints(point: Point): analytic {
  return !point ||
    pointDidPull(point) ||
    point?.is_archived ||
    point?.is_won === null
    ? null
    : {
        count: point?.is_won ? 1 : 0,
        chances: 1,
      };
}

export function analyticsTurnoversPerPossession(point: Point): analytic {
  return !point || pointOffensivePossessions(point) == 0 || point?.is_archived
    ? null
    : {
        count: pointTotalTurnovers(point),
        chances: pointOffensivePossessions(point),
      };
}
export function analyticsBlocksPerPossession(point: Point): analytic {
  return !point || !pointDefensivePossessions(point) || point?.is_archived
    ? null
    : {
        count: pointTotalDefenses(point),
        chances: pointDefensivePossessions(point),
      };
}
export function analyticsEndzone(point: Point): analytic {
  return !point ||
    (!point.endzone_scored &&
      !point.endzone_failed_forced &&
      !point.endzone_failed_other &&
      !point.endzone_failed_drop &&
      !point.endzone_failed_unknown) ||
    point?.is_archived
    ? null
    : {
        count: point.endzone_scored,
        chances:
          point.endzone_scored +
          point.endzone_failed_forced +
          point.endzone_failed_other +
          point.endzone_failed_drop +
          point.endzone_failed_unknown,
      };
}
// aggregates o/d line type mapping offense to 1 and defense to -1
export function analyticsLineType(point: Point): analytic {
  return !point || point?.is_archived || point?.is_won === null
    ? null
    : {
        count: pointDidPull(point) ? -1 : 1,
        chances: 1,
      };
}
export function analyticsHucking(point: Point): analytic {
  return !point ||
    (!point.hucks_completed &&
      !point.hucks_incomplete_forced &&
      !point.hucks_incomplete_other &&
      !point.hucks_incomplete_drop &&
      !point.hucks_incomplete_unknown) ||
    point?.is_archived
    ? null
    : {
        count: point.hucks_completed,
        chances:
          point.hucks_completed +
          point.hucks_incomplete_forced +
          point.hucks_incomplete_other +
          point.hucks_incomplete_drop +
          point.hucks_incomplete_unknown,
      };
}
export const pointsKeyTotalByGame = (
  points: Point[],
  key: keyof Point
): Record<number, number> =>
  points.reduce(
    (acc, point) => ({
      ...acc,
      [point.game_id]:
        (acc[point.game_id] ?? 0) +
        (typeof point[key] === "number" ? (point[key] as number) : 0),
    }),
    {} as Record<number, number>
  );

export const pointsScoresByGame = (points: Point[]) =>
  points.reduce((acc, point) => {
    if (!acc[point.game_id]) {
      acc[point.game_id] = { us: 0, them: 0 };
    }
    if (point.is_won === true) {
      acc[point.game_id].us += 1;
    } else if (point.is_won === false) {
      acc[point.game_id].them += 1;
    }
    return acc;
  }, {} as Record<number, { us: number; them: number }>);

export const pointCreatedAtBetween = (
  pointBefore: Point,
  pointAfter: Point,
  secondsInterval: number = 180
) =>
  timestampBetween(
    pointBefore?.created_at,
    pointAfter?.created_at,
    secondsInterval
  );

export const pointIsEqual = (point1: Point, point2: Point) =>
  isEqual(point1, point2);

// groups an array of points into an object with the game_id as key
export const pointsByGame = ({ points }: { points: Point[] }) =>
  points.reduce((output, p): { [key: number]: Point[] } => {
    if (output.hasOwnProperty(p.game_id)) {
      output[p.game_id].push(p);
    } else {
      output[p.game_id] = [p];
    }
    return output;
  }, {} as { [key: number]: Point[] });

type PlayerPointsType = {
  total: number;
  pulling: number;
  receiving: number;
  won: number;
  lost: number;
  breaks: number;
  holds: number;
  offense_possessions: number;
  defense_possessions: number;
};

export type PlayersPointsType = {
  [player_id: number]: PlayerPointsType;
};

export const pointsByPlayer = (
  points: Point[],
  events: Event[]
): PlayersPointsType => {
  const pointIds = points.map((p) => p.id);
  return events
    .filter((e) => pointIds.includes(e.point_id) && e.event === "in")
    .reduce((output, event) => {
      const playerId = event.player_id;
      const point = points.find((p) => event.point_id === p.id);
      if (!output?.[playerId]) {
        output[playerId] = {
          total: 0,
          pulling: 0,
          receiving: 0,
          won: 0,
          lost: 0,
          breaks: 0,
          holds: 0,
          offense_possessions: 0,
          defense_possessions: 0,
        };
      }

      const playerOutput = output[playerId];
      playerOutput.total++;
      playerOutput[point.did_pull ? "pulling" : "receiving"]++;
      playerOutput[point.is_won ? "won" : "lost"]++;
      playerOutput.breaks += pointIsBreak(point) ? 1 : 0;
      playerOutput.holds += pointIsHold(point) ? 1 : 0;
      playerOutput.offense_possessions += pointOffensivePossessions(point);
      playerOutput.defense_possessions += pointDefensivePossessions(point);

      return output;
    }, {} as PlayersPointsType);
};

export const gameIdsFromPoints = (points: Point[]): number[] => [
  ...new Set((points ?? []).map((p) => p.game_id)),
];
